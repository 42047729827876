@import "/src/app/styles";

.content {
	display: flex;
	align-items: center;
	justify-content: space-between;
	align-items: center;

	color: #fff;

	max-width: 1281px;
	width: 100%;

	& > div {
		cursor: pointer;
	}
}

.wrapper {
	display: flex;
	justify-content: center;

	padding: 0 24px;
	margin-bottom: 30px;

	@include mediaMax(720px) {
		flex-direction: column;
		justify-content: start;

		width: auto;
	}
}

.btns {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 9px;

	& > img {
		cursor: pointer;
	}

	@include mediaMax(720px) {
		display: none;
	}
}

.menuBlock {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 43px;
}

.menu {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 34px;

	font-size: 16px;
	font-weight: 600;
	line-height: 17.55px;
	text-align: center;

	@include mediaMax(720px) {
		display: none;
	}
}

.menuMobile {
	display: none;

	@include mediaMax(720px) {
		display: block;

		cursor: pointer;
	}
}

.mobileWrapper {
	position: fixed;
	top: 0;
	left: 0;

	background: url("../../assets/img/svg/shadowMobile.svg");
	background-repeat: no-repeat;
	background-size: cover;

	max-height: 100vh;
	height: 100vh;
	width: 100vw;

	z-index: 100;

	overflow: hidden;

	padding-left: 10px;
	padding-right: 24px;
}

.mobile {
}

.btnsMobile {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 22px;

	margin-top: 31px;

	& > img {
		cursor: pointer;
	}
}

.menuBlocksMobile {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 31px;

	margin-top: 34px;

	font-weight: 600;
	font-size: 16px;
	line-height: 110%;
	text-align: center;
	color: #fff;
}
