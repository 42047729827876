@import "/src/app/styles";

.content {
	//max-width: 1400px;
	width: 100vw;
}

.top {
	width: 100vw;
	background-image: url("../../modules/gamingEmpire/assets/img/png/backgroundGamingEmpire.png");
	background-repeat: no-repeat;
	background-size: cover;
}

.bottom {
	width: 100vw;
	background-image: url("../../modules/ourGames/assets/img/png/backgroundOurGames.png");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;

	@include mediaMax(590px) {
		background-image: url("../../modules/ourGames/assets/img/png/backgroundOurGamesMobile.png");
		background-size: contain;
	}
}

.torch {
	margin-top: -43px;

	@include mediaMax(590px) {
		height: 208px;
		width: auto;

		margin-top: -38px;
	}
}

.pick {
	margin-top: -14px;

	@include mediaMax(590px) {
		height: 182px;
		width: auto;

		margin-top: -11px;
	}
}

.map {
	margin-top: -6px;

	@include mediaMax(590px) {
		height: 182px;
		width: auto;

		margin-top: 3px;
	}
}

.card1 {
	background-image: url("../../modules/common/assets/img/svg/shadowNoGasFees.svg");
}

.card2 {
	background-image: url("../../modules/common/assets/img/svg/shadowOurMission.svg");
}

.card3 {
	background-image: url("../../modules/common/assets/img/svg/shadowWhatWeDo.svg");
}
