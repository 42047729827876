@import "/src/app/styles";

.content {
	position: relative;

	height: fit-content;
	width: 100%;

	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: start;
	gap: 45px;

	font-family: $mainFontFamily;
	font-weight: 600;
	font-size: 16px;
	line-height: 110%;
	text-align: center;
	color: #fff;

	padding: 24px;

	max-width: 1400px;

	@include mediaMax(730px) {
		justify-content: center;

		max-width: 390px;

		row-gap: 14px;
		column-gap: 45px;
	}

	&::after {
		position: absolute;
		content: "";
		top: 0;
		left: 0;
		background-image: url("../../assets/img/svg/shadowFooter.svg");
		height: fit-content;
		width: 100vw;
		z-index: 1;
	}

	& > svg {
		cursor: pointer;
		z-index: 2;
	}

	& > div {
		cursor: pointer;
	}
}

.wrapper {
	width: 100%;

	display: flex;
	justify-content: center;
}

.discord {
	display: block;

	@include mediaMax(590px) {
		display: none;
	}
}

.discordMobile {
	display: none;

	@include mediaMax(590px) {
		display: block;
	}
}
