@import "/src/app/styles";

.content {
	font-family: $mainFontFamily;
	font-weight: 700;
	font-size: 72px;
	line-height: 124%;
	color: #fff;

	margin-bottom: 63px;

	display: flex;
	justify-content: center;
}

.mainContent {
	border: 2px solid #3d7fff;
	border-radius: 45px;

	max-width: 1352px;

	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;

	padding: 0 34px 0 43px;
	margin: 0 24px;

	@include mediaMax(1360px) {
		justify-content: center;

		padding: 31px 34px 19px 43px;
	}

	@include mediaMax(720px) {
		padding: 31px 17px 19px 6px;
	}

	@include mediaMax(460px) {
		width: 341px;
	}
}

.titleBlock {
	max-width: 528px;

	font-family: $mainFontFamily;
	font-weight: 700;
	font-size: 72px;
	line-height: 124%;
	color: #fff;

	@include mediaMax(1360px) {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		text-align: center;
	}

	@include mediaMax(740px) {
		font-size: 60px;
	}

	@include mediaMax(570px) {
		font-size: 40px;

		text-align: left;
		align-items: baseline;
	}
}

.title {
	margin-bottom: 40px;

	@include mediaMax(570px) {
		max-width: 318px;

		margin-bottom: 18px;
	}
}

.skaleBlock {
	border-radius: 78px;
	width: 351px;
	height: 98px;

	background: linear-gradient(90deg, #236eff 0%, #4aece2 100%);

	display: flex;
	align-items: center;
	justify-content: center;

	@include mediaMax(570px) {
		width: 169px;
		height: 47px;

		margin-left: 6px;

		& > img {
			width: 134px;
			height: 35px;
		}
	}
}

.dragon {
	margin-top: -56px;

	@include mediaMax(1330px) {
		margin-top: 0;
	}

	@include mediaMax(740px) {
		width: 96%;
		height: auto;
	}

	@include mediaMax(570px) {
		width: 354px;
		height: 354px;

		margin-left: 20px;
	}
}
